import Layout from "@/layout";
import * as React from "react";
import { Contact } from "@/components";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const Con = () => {
  const {host} = useLocation();
  const canonical = `${host}/contact`;
  return (
    <Layout>
      <SEO title="Contact us" canonical={canonical}/>
      <Contact />
    </Layout>
  );
};

export default Con;
